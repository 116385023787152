import * as React from "react"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"
import IndexPage from "./home"

const Home = () => {
  return (
    <main>
      <FaviconHeader />
      {/* <DomeLandingPageV2 /> */}
      <IndexPage />
    </main>
  )
}

export default Home
